import React from 'react';
import {
  AppBar,
  Toolbar,
  Container,
  IconButton,
  Box,
  Icon,
  Logo,
  ThemeProvider,
  mainTheme,
  worthyShineTheme,
  styled,
  CssBaseline,
} from '@worthy-npm/worthy-common-ui-components';
import _ from 'lodash';
import { directToItemTypePage, directToHomePage } from '../../lib/redirectUtils';

const Main = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  overflow: 'hidden',
  height: 'inherit',
}));

const DummyCol = styled(Box)`
  width: 32px;
  height: 32px;
`;

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.highlight.main,
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  width: 30,
  height: 30,
}));

function CommonLayout({
  children,
  hideCloseBtn = false,
}: {
  children: React.ReactNode;
  hideCloseBtn?: boolean;
}) {
  return (
    <ThemeProvider theme={_.merge(mainTheme, worthyShineTheme)}>
      <CssBaseline />
      <Main>
        <AppBar style={{ zIndex: 10 }} elevation={0} position="sticky" color="transparent">
          <Container maxWidth={false}>
            <Toolbar variant="regular" disableGutters={false}>
              <Box
                display="grid"
                gridTemplateColumns="70px auto 70px"
                alignItems="center"
                justifyItems="stretch"
                width="100%"
                height="76px"
              >
                {hideCloseBtn ? (
                  <DummyCol />
                ) : (
                  <StyledCloseButton
                    data-automation="home-button"
                    onClick={directToItemTypePage}
                    variant="default"
                    size="large"
                    disableRipple
                  >
                    <Icon.CloseIcon fontSize="small" />
                  </StyledCloseButton>
                )}

                <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                  <Box onClick={directToHomePage} style={{ cursor: 'pointer' }}>
                    <Logo />
                  </Box>
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        {children}
      </Main>
    </ThemeProvider>
  );
}

CommonLayout.defaultProps = {
  hideCloseBtn: false,
};

export default CommonLayout;
